import React from 'react'

export const Footer = () => {
  return (
    <>
      <div className="footer-strap">&nbsp;</div>
      <footer>
        <ul className="horizontal">
          <li>
            <ul>
              <li><a href="https://www.facebook.com/Oxted-and-Dorking-Facial-Aesthetics-781850308624922/"
                     title="Like us on Facebook">
                <svg className="facebook-icon custom-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                  <rect className="outer-shape" x="0" y="0" width="100" height="100" rx="20" ry="20"
                        style={{opacity: 1, fill: 'rgb(255, 255, 255)'}} />
                  <path className="inner-shape" style={{opacity: 1, fill: 'rgb(244, 127, 79)'}}
                        transform="translate(18.5,18.5) scale(0.63)"
                        d="M82.667,1H17.335C8.351,1,1,8.351,1,17.336v65.329c0,8.99,7.351,16.335,16.334,16.335h65.332 C91.652,99.001,99,91.655,99,82.665V17.337C99,8.353,91.652,1.001,82.667,1L82.667,1z M84.318,50H68.375v42.875H50V50h-8.855V35.973 H50v-9.11c0-12.378,5.339-19.739,19.894-19.739h16.772V22.3H72.967c-4.066-0.007-4.57,2.12-4.57,6.078l-0.023,7.594H86.75 l-2.431,14.027V50z" />
                </svg>
                <span>/Oxted &amp; Dorking Facial Aesthetics</span></a></li>
            </ul>
          </li>
        </ul>
      </footer>
    </>
  )
}