/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../style/style.scss"
import {Footer} from "./footer";

const Layout = ({ children, pageName }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="page" className={`site ${pageName}`}>
      <div className="site-inner">
        <Header pageName={pageName} />
        <div id="content" className="site-content">{children}</div>
        <Footer />
      </div>
    </div>)
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
