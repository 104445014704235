import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {Menu} from "./menu";

const Header = ({ pageName }) => (
  <>
    <header id="masthead" className="site-header" role="banner">
    <div className="site-header-main">
      <Menu pageName={pageName} />
      <div className="site-branding" id="page-title">
        <h1 className="entry-title">
          Oxted &amp; Dorking <br/>Facial Aesthetics
        </h1>
      </div>
    </div>
  </header>
    <div className="shadow">&nbsp;</div>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
