import React from 'react'

const MenuItem = ({currentPageName, name, link, title}) => {
  return (<li className={currentPageName===name?'selected':null}><a href={link}><span className="top">&nbsp;</span><span className="menu-item-title">{title}</span></a></li>)
}

export class Menu extends React.Component {

  state = {
    showing: false
  }

  render() {
    const pageName = this.props.pageName

    return (
      <div id="site-header-menu" className="site-header-menu">
        <nav id="site-navigation" className={`main-navigation ${this.state.showing?'show-menu':''}`} role="navigation" aria-label="Primary Menu">
          <a id="menu-icon" onClick={() => {this.setState({showing: true})}}>
            <svg viewBox="0 0 18 15">
              <path fill="#424242"
                    d="M18,1.484c0,0.82-0.665,1.484-1.484,1.484H1.484C0.665,2.969,0,2.304,0,1.484l0,0C0,0.665,0.665,0,1.484,0 h15.031C17.335,0,18,0.665,18,1.484L18,1.484z"/>

              <path fill="#424242"
                    d="M18,7.516C18,8.335,17.335,9,16.516,9H1.484C0.665,9,0,8.335,0,7.516l0,0c0-0.82,0.665-1.484,1.484-1.484 h15.031C17.335,6.031,18,6.696,18,7.516L18,7.516z"/>

              <path fill="#424242"
                    d="M18,13.516C18,14.335,17.335,15,16.516,15H1.484C0.665,15,0,14.335,0,13.516l0,0 c0-0.82,0.665-1.484,1.484-1.484h15.031C17.335,12.031,18,12.696,18,13.516L18,13.516z"/>
            </svg>
          </a>
          <div id="menu-container">
            <a href="#" id="close-menu" onClick={() => {this.setState({showing: false})}}>
              <svg viewBox="0 0 20 20">
                <line x1="2" y1="2" x2="18" y2="18" style={{strokeWidth: 2, stroke: 'black'}}/>
                <line x1="18" y1="2" x2="2" y2="18" style={{strokeWidth: 2, stroke: 'black'}}/>
              </svg>
            </a>
            <nav>
              <ul id="menu-main-menu">
                <MenuItem currentPageName={pageName} link="/" title="Home" name="home"/>
                <MenuItem currentPageName={pageName} link="/men" title="Men" name="men"/>
                <MenuItem currentPageName={pageName} link="/women" title="Women" name="women"/>
                <MenuItem currentPageName={pageName} link="/treatments-and-prices" title="Treatments & Prices"
                          name="treatments-and-prices"/>
                <MenuItem currentPageName={pageName} link="/about-us" title="About Us" name="about-us"/>
                <MenuItem currentPageName={pageName} link="/contact" title="Contact / Visit" name="contact"/>
              </ul>
            </nav>
          </div>
        </nav>

      </div>
    )
  }
}